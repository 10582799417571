<template>
   <div class="editor">
      <h1>This is an editor page</h1>
   </div>
   <n-button-group>
      <n-button type="primary" @click="openEdit('File View','ccb3c823-7b5c-46c5-9272-891129165a16','file','')">Files</n-button>
      <n-button type="primary" @click="openEdit('TMPPro View','e4d4556d-03d6-408b-88fb-b9d4c713493c','text','Bye World')">Text</n-button>
      <n-button type="primary" @click="openEdit('Link View','458b8370-3e73-49f3-975a-a539fd6dc715','link','')">Link</n-button>
      <n-button type="primary" @click="openEdit('Image View','41ff5074-3388-420b-a771-7bc963337e3a','image','')">Image</n-button>
      <n-button type="primary" @click="openEdit('Video View','305acd05-c71f-4f40-916b-50be68e44e2c','video','')">Video</n-button>
      <n-button type="primary" @click="openEdit('Double View','376a1f21-5840-4b57-affc-352b63de0b2c','double', '42')">Double</n-button>
      <n-button type="primary" @click="openEdit('Player Director View','16979b5d-2161-4ca0-b35e-f136448218e6','playerDirector','0.42')">Player Director</n-button>
      <n-button type="primary" @click="openEdit('UTC Timer View','a1cb9bbd-746f-46dc-acfe-24b0a61eb115','utcTimer',sampleDateTime)">UTC Timer</n-button>
      <n-button type="primary" @click="openEdit('UTC Timer View','a1cb9bbd-746f-46dc-acfe-24b0a61eb115','utcTimer','')">UTC Timer Undefined</n-button>
   </n-button-group>
   <FilesUploadOld/>
   <GenericEdit/>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import FilesUploadOld from '@/components/FilesUploadOld.vue'
import GenericEdit from '@/components/GenericEdit.vue'

function openEdit (name, id, edittype, value) {
    var message = {
      event: "edit",
      edittype: edittype,
      id: id,
      name: name,
      value: value
    };
    console.log("[EditorView] posting ", message);
    window.parent.postMessage(message, "*");
    console.log("[EditorView] posted", message);
}

const sampleDateTime = computed(() => {
  return ((Date.now() / 1000) + 60) + "," + ((Date.now() / 1000) + (5 * 60))
})

onMounted (() => {
   const startTest = new CustomEvent('startTest', {
      bubbles: true,
      cancelable: false,
      composed: true
   })
   document.getElementById('app').dispatchEvent(startTest)
})
</script>