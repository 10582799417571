<template>
  <n-notification-provider placement="top">
    <router-view/>
  </n-notification-provider>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.title {
   font-size: 24px;
   font-weight: lighter;
   margin: 0px 0px 8px 0px;
}

.subtitle {
   font-size: 10px;
   color: #777;
   font-weight: lighter;
   margin: 0px;
}
</style>
