import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    domain: 'https://api.holofair.net/',
    code: String,
    files: {
      page: 0,
      removing: -1,
      loading: false,
      loadingMore: false,
      noFiles: false,
      records: [],
      end: false
    },
    conference: {
      loading: false,
      joined: false,
      readyToJoin: false,
      mic: {
        loading: false,
        active: true
      },
      video: {
        loading: false,
        active: true
      },
      screenShare: {
        loading: false,
        active: true
      },
      users: [
        {
          id: "loading",
          speaking: false,
          video: {
            active: false,
            stream: null
          },
          screen: {
            active: false,
            stream: null
          },
          name: "loading",
          userRole : [
            {id:'', label: "English Mute", key: 'k1', active: true},
            {id:'', label: "Arabic Mute", key: 'k2', active: true},

          ],
          audio: false,
          options: [
            {
              id: "",
              label: 'Maximize',
              key: 'fullscreen',
              active: false,
              moderator: false
            },
            {
              id: "",
              label: 'Local Mute',
              key: 'mute',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Remote Mute',
              key: 'remote-mute',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Block Mic',
              key: 'mic-block-toggle',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Stop Video',
              key: 'video',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Block Video',
              key: 'video-block-toggle',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Disconnect',
              key: 'disconnect',
              active: false,
              moderator: true
            }
          ]
        }
      ],
      selectedUser: null
    }
  },
  getters: {
  },
  mutations: {
    setFilesLoading (state, loading) {
      state.files.loading = loading
    },
    setFilesLoadingMore (state, loading) {
      state.files.loadingMore = loading
    },
    setRemovingFileIndex(state, index) {
      state.files.removing = index
    },
    removeFile(state, index) {
      state.files.records.splice(index, 1)
    },
    setFilesPage(state, page) {
      state.files.page = page
    },
    setFilesPageEnd(state, end) {
      state.files.end = end
    },
    setFiles(state, files) {
      state.files.records = files
    },
    setNoFiles(state, noFiles) {
      state.files.noFiles = noFiles
    },
    clearFiles(state) {
      state.files.records = []
      state.files.page = 0
      state.files.end = false
      state.files.noFiles = false
    },
    resetConference(state) {
      state.conference.users = [
        {
          id: "loading",
          speaking: false,
          video: {
            active: false,
            stream: null
          },
          screen: {
            active: false,
            stream: null
          },
          name: "loading",
          audio: false,
          options: [
            {
              id: "",
              label: 'Maximize',
              key: 'fullscreen',
              active: false,
              moderator: false
            },
            {
              id: "",
              label: 'Mute',
              key: 'mute',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Block Mic',
              key: 'mic-block-toggle',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Stop Video',
              key: 'video',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Block Video',
              key: 'video-block-toggle',
              active: false,
              moderator: true
            },
            {
              id: "",
              label: 'Disconnect',
              key: 'disconnect',
              active: false,
              moderator: true
            }
          ]
        }
      ]
      state.conference.loading = false
      state.conference.readyToJoin = true
      state.conference.joined = false

      state.conference.mic.loading = false
      state.conference.video.loading = false
      state.conference.screenShare.loading = false

      state.conference.mic.active = true
      state.conference.video.active = false
      state.conference.screenShare.active = false
    },
    joinedConference(state) {
      state.conference.loading = false
      state.conference.readyToJoin = false
      state.conference.joined = true
    },
    setSelectedUser(state, key) {
      state.conference.selectedUser = key
    },
    toggleFullScreen(state, id) {
      var index = state.conference.users.findIndex(user => user.id == id)
      console.log("[store] index", index);
      state.conference.users[index].options[0].active = !state.conference.users[index].options[0].active
      if(state.conference.users[index].options[0].active) {
        state.conference.selectedUser = id
        state.conference.users[index].options[0].label = "Minimize"
      } else {
        state.conference.selectedUser = null
        state.conference.users[index].options[0].label = "Maximize"
      }
    },

    roleSwitcherEn(state, id){
      console.log(id)
      var index = state.conference.users.findIndex(user => user.id == id)
      console.log("[store] index", index);
     state.conference.users[index].userRole[0].active = !state.conference.users[index].userRole[0].active
      if(state.conference.users[index].userRole[0].active && state.conference.users[index].userRole[0].active) {
        state.conference.selectedUser = id
        state.conference.users[index].userRole[0].label = "English Mute"

      } else {
        state.conference.selectedUser = null
        state.conference.users[index].userRole[0].label = "English Unmute"

      }



    },


    roleSwitcherAr(state, id){
      console.log(id)
      var index = state.conference.users.findIndex(user => user.id == id)
      console.log("[store] index", index);

      state.conference.users[index].userRole[1].active = !state.conference.users[index].userRole[1].active
      if(state.conference.users[index].userRole[1].active && state.conference.users[index].userRole[1].active) {
        state.conference.selectedUser = id
        state.conference.users[index].userRole[1].label = "Arabic Mute"

      } else {
        state.conference.selectedUser = null
        state.conference.users[index].userRole[1].label = "Arabic Unmute"

      }


    },





    setConferenceLoading(state, loading) {
      state.conference.loading = loading
    },
    setConferenceJoined(state, joined) {
      state.conference.joined = joined
    },
    setConferenceReadyToJoin(state, readyToJoin) {
      state.conference.readyToJoin = readyToJoin
    },
    setConferenceMicLoading (state, value) {
      state.conference.mic.loading = value
    },
    setConferenceVideoLoading (state, value) {
      state.conference.video.loading = value
    },
    setConferenceScreenShareLoading (state, value) {
      state.conference.screenShare.loading = value
    },
    setConferenceMicActive (state, value) {
      state.conference.mic.active = value
    },
    setConferenceVideoActive (state, value) {
      state.conference.video.active = value
    },
    setConferenceScreenShareActive (state, value) {
      state.conference.screenShare.active = value
    },
    addConferenceUser(state, data) {
      console.log("[store] adding user", data);
      var index = state.conference.users.findIndex(user => user.id == data.id)
      if(index == -1) state.conference.users.push(data)
      else state.conference.users[index] = data
    },
    setConferenceUserSpeaking (state, data) {
      var index = state.conference.users.findIndex(user => user.id == data.participant.id)
      state.conference.users[index] = data.isSpeaking
    },
    setConferenceUser(state, data) {
      var participant = data.participant
      var streamWithType = data.streamWithType
      var index = state.conference.users.findIndex(user => user.id == participant.id)
      state.conference.users[index].audio = participant.audioTransmitting
      if(streamWithType.type === "Camera") {
        state.conference.users[index].video.active = participant.videoTransmitting
        if(state.conference.users[index].video.active) state.conference.users[index].video.stream = streamWithType
        else state.conference.users[index].video.stream = null
      } else if(streamWithType.type === "ScreenShare") {
        state.conference.users[index].screen.active = true
        if(state.conference.users[index].screen.active) state.conference.users[index].screen.stream = streamWithType
        else state.conference.users[index].screen.stream = null
      }
    },
    removeConferenceUser(state, data) {
      var index = state.conference.users.findIndex(user => user.id == data.participant.id)
      state.conference.users[index].audio = data.participant.audioTransmitting;
      state.conference.users[index].video.active = data.participant.videoTransmitting;
      if(data.streamWithType.type === "ScreenShare") {
        state.conference.users[index].screen.active = false;
        if(index === Object.keys(state.conference.users)[0])
          state.conference.screenShare.active = false;
      }
      if(data.streamWithType.type === "leave") delete state.conference.users[index];
    }
  },
  actions: {
    addUrls ({ commit, dispatch, state }, data) {
      commit("setFilesLoading", true)
      axios.post(state.domain + 'eventFiles/create.php', {
        userid: data.userid,
        url: data.url,
        code: data.code
      }).then(response => {
        commit("setFilesLoading", false)
        dispatch("searchFiles", {
          code: data.code,
          Keyword: data.Keyword
        })
        console.log('add response', response)
      }).catch(error => {
        commit("setFilesLoading", true)
        console.error('There was an error!', error)
      })
    },
    searchFiles ({ commit, dispatch }, data) {
      commit("clearFiles")
      console.log('[store] searching', data)
      dispatch("moreFiles", data)
    },
    moreFiles({ commit, state }, data) {
      if (state.files.loadingMore === false && state.files.end === false) {
        commit("setFilesPage", state.files.page + 1)
        commit("setFilesLoadingMore", true)
        axios.get(state.domain + 'eventFiles/read_paging.php?page=' + state.files.page +
          '&code=' + data.code +
          '&keyword=' + data.Keyword).then(response => {
          if (response.data.records) {
            commit("setFiles", [...state.files.records, ...response.data.records])
          }
          commit("setFilesPageEnd", response.data.paging.last === '')
        }).catch(error => {
          if (error.response.status === 404) commit("setNoFiles", true)
          commit("setFilesPage", state.files.page - 1)
        }).finally(() => {
           commit("setFilesLoadingMore", false)
        })
        console.log('[more] loading more')
      }
    },
    removeFile ({ commit, state }, data) {
      commit("setRemovingFileIndex", data.index)
      axios.delete(state.domain + 'eventFiles/delete.php?id=' + data.id).then(response => {
        commit("removeFile", data.index)
        console.log('remove response', response)
      }).catch(error => {
        console.error('There was an error!', error)
      }).finally(() => {
        commit("setRemovingFileIndex", -1)
      })
    }
  },
  modules: {
  }
})


/*

var names = 'Harry,John,Clark,Peter,Rohn,Alice';
var nameArr = names.split(',');
console.log(nameArr);

// Accessing individual values
alert(nameArr[0]); // Outputs: Harry
alert(nameArr[1]); // Outputs: John
alert(nameArr[nameArr.length - 1]); // Outputs: Alice

*/