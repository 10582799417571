<template>
   <n-drawer v-model:show="show" :width="512" :on-after-leave="onCloseDrawer">
      <n-drawer-content closable>
         <n-space vertical>
            <n-input
               v-if="type === 'textarea'"
               type="textarea"
               v-model:value="value"
               :maxlength="1024"
               placeholder="Any text..."
               :autosize="{ minRows: 3 }"
               show-count clearable/>
            <n-input-number
               v-else-if="type === 'number'"
               v-model:value="value"
               :maxlength="32"
               :min="min"
               :max="max"
               placeholder="Any number..."
               show-count clearable/>
            <n-date-picker
               v-else-if="type === 'datetimerange'"
               v-model:value="value"
               :on-confirm="setTotalDuration"
               type="datetimerange"
               clearable/>
            <div v-if="type === 'datetimerange'">
              <p>Time left until start: {{ timeLeftUntilStart }}</p>
              <p>Total duration: {{ totalDuration }}</p>
              <p>Time left until end: {{ timeLeftUntilEnd }}</p>
            </div>
         </n-space>
         <template #header>
           <p class="title">{{ name }}</p>
           <p class="subtitle">{{ id }}</p>
         </template>
         <template #footer>
           <n-button @click="submit">Submit</n-button>
         </template>
      </n-drawer-content>
   </n-drawer>
</template>

<script setup>
import { onUnmounted, onMounted, ref } from 'vue'

const show = ref(false)

const type = ref("textarea")
const min = ref(undefined)
const max = ref(undefined)

const name = ref(String)
const id = ref(String)
const initialValue = ref("")
const value = ref("")

const timeLeftUntilStart = ref("...")
const totalDuration = ref("...")
const timeLeftUntilEnd = ref("...")
var _timeLeftUntilStartTimer = ref(null)
var _timeLeftUntilEndTimer = ref(null)

onMounted(() => {
  document.getElementById('app').addEventListener('text', openTextDrawer)
  document.getElementById('app').addEventListener('double', openNumberDrawer)
  document.getElementById('app').addEventListener('playerDirector', openPlayableDirectorDrawer)
  document.getElementById('app').addEventListener('utcTimer', openUTCTimerDrawer)
})

onUnmounted(() => {
  document.getElementById('app').removeEventListener('text', openTextDrawer)
  document.getElementById('app').removeEventListener('double', openNumberDrawer)
  document.getElementById('app').removeEventListener('playerDirector', openPlayableDirectorDrawer)
  document.getElementById('app').removeEventListener('utcTimer', openUTCTimerDrawer)
})

function openTextDrawer (event) {
  type.value = "textarea"
  openDrawer(event)
  value.value = event.detail.value
  initialValue.value = value.value
}

function openNumberDrawer (event) {
  type.value = "number"
  min.value = undefined
  max.value = undefined
  openDrawer(event)
  value.value = Number(event.detail.value)
  initialValue.value = value.value
}

function openPlayableDirectorDrawer (event) {
  type.value = "number"
  min.value = 0
  max.value = 1
  openDrawer(event)
  value.value = Number(event.detail.value)
  initialValue.value = value.value
}

function openUTCTimerDrawer (event) {
  type.value = "datetimerange"
  openDrawer(event)
  var timestamps = event.detail.value.split(",")
  value.value = [timestamps[0]*1000,timestamps[1]*1000]
  if(Number.isNaN(new Date(value.value[0]).getTime()) || Number.isNaN(new Date(value.value[1]).getTime())) {
    value.value = undefined
  }
  timeLeftUntilStartTimer()
  timeLeftUntilEndTimer()
  _timeLeftUntilStartTimer = setInterval(timeLeftUntilStartTimer, 1000)
  _timeLeftUntilEndTimer = setInterval(timeLeftUntilEndTimer, 1000)
  setTotalDuration(value.value)
  initialValue.value = value.value
}

function setTotalDuration(newValue) {
  if(newValue === undefined) totalDuration.value = formatTime(0)
  else totalDuration.value = formatTime((new Date(newValue[1] - Date.now()) / 1000) - (new Date(newValue[0] - Date.now()) / 1000))
}

function timeLeftUntilStartTimer() {
  if(value.value !== undefined) timeLeftUntilStart.value = formatTime((new Date(value.value[0] - Date.now())) / 1000)
  else timeLeftUntilStart.value = formatTime(0)
}

function timeLeftUntilEndTimer() {
  if(value.value !== undefined) timeLeftUntilEnd.value = formatTime((new Date(value.value[1] - Date.now())) / 1000)
  else timeLeftUntilEnd.value = formatTime(0)
}

function formatTime(seconds) {
  if(seconds >= 0) {
    var days = Math.floor(seconds/24/60/60)
    var hoursLeft = Math.floor((seconds) - (days*86400))
    var hours = Math.floor(hoursLeft/3600)
    var minutesLeft = Math.floor((hoursLeft) - (hours*3600))
    var minutes = Math.floor(minutesLeft/60)
    var remainingSeconds = Math.floor(seconds % 60)
    return pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds)
  } else {
    return "00:00:00:00"
  }
}

function pad(n) {
  return (n < 10 ? "0" + n : n)
}

function openDrawer (event) {
  show.value = true
  name.value = event.detail.name
  id.value = event.detail.id
}

function onCloseDrawer () {
  document.getElementById('app').dispatchEvent(new Event('unlock', {
    bubbles: true,
    cancelable: false,
    composed: true
  }))
}

function submit () {
  document.getElementById('app').dispatchEvent(new CustomEvent('submit', {
    detail: {
      value: type.value === "datetimerange" ?  [value.value[0]/1000,value.value[1]/1000].join() : value.value
    },
    bubbles: true,
    cancelable: false,
    composed: true
  }))
  if(type.value === "datetimerange") {
    clearInterval(_timeLeftUntilStartTimer)
    clearInterval(_timeLeftUntilEndTimer)
  }
  show.value = false
}
</script>
