<template>
  <n-drawer v-model:show="show" :width="512" :mask-closable="!loading" :on-after-leave="onCloseDrawer" :trap-focus="false">
    <n-drawer-content :closable="!loading">
      <template #header>
        <p class="title">{{ name }}</p>
        <p class="subtitle">{{ id }}</p>
      </template>
      <n-space vertical>
        <n-upload
          multiple
          :directory-dnd="false"
          :disabled="loading"
          :default-file-list="uploadList"
          :data="uploadForm"
          :action="s3UploadAction"
          :on-finish="onSuccess"
          :on-before-upload="beforeUpload"
          accept=".jpg,.png,.mp4,.pdf"
          :max="5">
          <n-upload-dragger>
            <div style="margin-bottom: 12px">
              <n-icon size="48" :depth="3">
                <cloud-upload-outline-icon />
              </n-icon>
            </div>
            <n-text style="font-size: 16px">
              Click or drag a file to this area to upload
            </n-text>
          </n-upload-dragger>
        </n-upload>
        <n-form
          ref="uploadFormRef"
          inline
          :label-width="80"
          :model="uploadForm"
          :rules="uploadFormRules"
          size="medium">
          <n-form-item label="Url" path="url" class="flex-grow"> <!--asmaa 27-02-23-->
            <n-input type="textarea" v-model:value="uploadForm.url" placeholder="Input Urls" />
          </n-form-item>
          <n-form-item>
            <n-spin :show="loading">
              <n-button strong secondary circle type="primary" :disabled="uploadForm.url === ''" @click="add">
                <template #icon>
                  <n-icon><add-icon /></n-icon>
                </template>
              </n-button>
            </n-spin>
          </n-form-item>
        </n-form>
        <n-form
          ref="searchFormRef"
          inline
          :label-width="80"
          :model="uploadForm"
          size="medium">
          <n-form-item label="Keyword" path="Keyword" class="flex-grow"> <!--by asmaa 27-02-23-->
            <n-input v-model:value="uploadForm.Keyword" placeholder="Input Keyword" /> <!--by asmaa 27-02-23-->
          </n-form-item>
          <n-form-item>
            <n-spin :show="loading">
              <n-button strong secondary circle type="primary" :disabled="uploadForm.Keyword === ''"  @click="searchFiles"> <!--by asmaa 27-02-23-->
                <template #icon>
                  <n-icon><add-icon /></n-icon>
                </template>
              </n-button>
            </n-spin>
          </n-form-item>
        </n-form>
        <FilesList :code="uploadForm.code" :Keyword="uploadForm.Keyword"></FilesList>
      </n-space>
    </n-drawer-content>
  </n-drawer>
</template>

<script setup>
import { reactive, ref, computed, onUnmounted, onMounted } from 'vue'
import { useStore } from 'vuex'
import { CloudUploadOutline as CloudUploadOutlineIcon, Add as AddIcon } from "@vicons/ionicons5";
import FilesList from '@/components/FilesList.vue'
import { useNotification } from 'naive-ui'

const notification = useNotification()
const store = ref(useStore())

const uploadForm = reactive({
  code: '5640664',
  userid: -1,
  url: '',
  Keyword: ''
})

const uploadFormRules = reactive({
  url: {
    required: true,
    message: "Please input at least one url",
    trigger: "blur"
  }
})

const maxbps = 4096000;
const name = ref(String)
const id = ref(String)
const initialValue = ref("")
const value = ref(String)
const show = ref(false)

const s3UploadAction = computed(() => {
  return store.value.state.domain + 'upload-to-s3.php'
})

const loading = computed(() => {
  return store.value.state.files.loading
})

const uploadedCount = ref(0)
const totalCount = ref(0)
const uploadList = ref([])

onMounted(() => {
  window.addEventListener('message', receiveMessage, false)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (urlParams.has('code')) {
    uploadForm.code = urlParams.get('code')
    searchFiles()
  }
  if (urlParams.has('uuid')) {
    uploadForm.uuid = urlParams.get('uuid')
    searchFiles()
  }
})

onUnmounted(() => {
  window.removeEventListener('message', receiveMessage)
})

function receiveMessage(event) {
  var message = event.data;
  if (event.source === window.parent) {
    if (message.event === 'edit') {
      openDrawer(message)
    } else if (message.event === 'submitted') {
      closeDrawer()
    }
  }
}

function openDrawer (data) {
  show.value = true
  name.value = data.name
  id.value = data.id
  value.value = data.value
  initialValue.value = data.value
  searchFiles()
}

function closeDrawer() {
  show.value = false
}

function add() {
  store.value.dispatch("addUrls", uploadForm)
}

function searchFiles() {
  store.value.dispatch("searchFiles", {
    code: uploadForm.code,
    Keyword: uploadForm.Keyword
  })
}

function beforeUpload (rawFile) {
  store.value.commit("setFilesLoading", true)
  console.log('beforeUpload rawFile', rawFile)
  return new Promise((resolve) => {
    if (rawFile.file.type === "video/mp4") {
      const reader = new FileReader();

      reader.onloadend = function () {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(rawFile.file.file);

        video.onloadedmetadata = function () {
          console.log(`rawFile:`, rawFile);
          console.log(`size: ${rawFile.file.file.size} bytes`);
          console.log(`duration: ${video.duration} seconds`);
          const bitsPerByte = 8;
          const bps = (rawFile.file.file.size * bitsPerByte) / video.duration;
          console.log(`Data rate: ${bps} bps`);

          if (bps > maxbps) {
            console.log(`Data rate exceeds ${maxbps} bps. Aborting upload.`);
            resolve(false); // Data rate exceeds the limit, reject.
            store.value.commit("setFilesLoading", false)
            notification['error']({
              content: "Unsupported video data rate",
              meta: `Data rate of video ${rawFile.file.file.name}, ${bps} bps, exceeds ${maxbps} bps. Please, reduce the data rate of the video to lower than ${maxbps} bps and try again.`,
              duration: 6500,
              keepAliveOnHover: true
            });
          } else {
            resolve(true); // Data rate is within limits, resolve with true.
          }
        };
      };

      reader.readAsDataURL(rawFile.file.file);
    } else {
      resolve(true); // For non-video files, resolve with true.
    }
  });
}

function onSuccess () {
  console.log('onSuccess', uploadedCount.value, "out of", totalCount.value)
  uploadedCount.value++
  store.value.commit("setFilesLoading", uploadedCount.value < totalCount.value)
  if (uploadedCount.value >= totalCount.value) {
    searchFiles()
    uploadedCount.value = 0
    totalCount.value = 0
  }
}

function onCloseDrawer () {
  document.getElementById('app').dispatchEvent(new Event('unlock', {
    bubbles: true,
    cancelable: false,
    composed: true
  }))
}
</script>

<style>
.upload {
  margin-bottom: 20px;
}

.fileItem {
  padding: 12px;
  border-radius: 4px;
  margin-top: 20px;
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.link {
  font-size: 12px;
}

.userAndDateTime {
  font-size: 10px;
}

#infinite-list {
  height: 512px;
  padding: 0;
  margin: 0;
  list-style: none;
}

#infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px;
}

#infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}

/*by asmaa*/
.flex-grow {
  flex-grow: 1;
}
</style>
