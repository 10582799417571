<template>
  <n-space vertical>
    <div id="infinite-list" style="overflow: auto">
      <div v-for="(record, index) in files" :key="index" class="fileItem">
        <p>{{ decodeURI(record.url.substring(record.url.lastIndexOf('/') + 1)) }}</p>
        <p class="link">{{ decodeURI(record.url) }}</p>
        <p class="userAndDateTime">Added by {{ record.userName ? record.userName : 'Unknown' }} on {{ record.created }}</p>
        <n-space>
          <n-button id="selectFile" @click="selectFile(record.url)" ghost>
            <template #icon>
              <checkmark-icon />
            </template>
          </n-button>
          <n-button :loading="indexRemoved == index" :disabled="indexRemoved == index" @click="removeFile(index, record.id)" ghost>
            <template v-if="indexRemoved != index" #icon>
              <trash-bin-outline-icon />
            </template>
          </n-button>
        </n-space>
      </div>
      <div v-if="loading" class="fileItem">
        <n-skeleton text :repeat="3"/><n-skeleton text style="width: 60%" />
      </div>
      <div v-if="noFiles">
        <p>No files available in this world (code: {{ props.code }})</p>
      </div>
    </div>
  </n-space>
</template>

<script setup>
import { defineProps, computed, ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { Checkmark as CheckmarkIcon, TrashBinOutline as TrashBinOutlineIcon } from "@vicons/ionicons5";

const store = ref(useStore())
const infiniteList = ref(null)

const props = defineProps({
  code: String,
  Keyword: String
});

const files = computed(() => {
  return store.value.state.files.records
})

const indexRemoved = computed(() => {
  return store.value.state.files.removing
})

const loading = computed(() => {
  return store.value.state.files.loading
})

const noFiles = computed(() => {
  return store.value.state.files.noFiles
})

onMounted(() => {
   infiniteList.value = document.getElementById('infinite-list')
   infiniteList.value.addEventListener('scroll', handleScroll)
   store.value.dispatch("searchFiles", {
      code: props.code,
      Keyword: props.Keyword
   })
})

onUnmounted(() => {
   infiniteList.value.removeEventListener("scroll", handleScroll)
})

function handleScroll() {
  if(infiniteList.value.scrollTop + infiniteList.value.clientHeight >= infiniteList.value.scrollHeight) {
    store.value.dispatch("moreFiles", {
        code: props.code,
        Keyword: props.Keyword
    })
  }
}

function selectFile (value) {
  const submit = new CustomEvent('submit', {
    detail: {
      value: value
    },
    bubbles: true,
    cancelable: false,
    composed: true
  })
  document.getElementById('app').dispatchEvent(submit)
}

function removeFile (index, id) {
  store.value.dispatch("removeFile", {
    id: id,
    index: index
  })
}
</script>

<style>
.fileItem {
  padding: 12px;
  border-radius: 12px;
  margin-top: 20px;
  border-color: #00000047;
  border-style: solid;
  border-width: 1px;
}
</style>
