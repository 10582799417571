import { createApp } from 'vue'
import naive from "naive-ui";
import App from './App.vue'
import router from './router'

var instance = createApp(App).use(naive)

instance.config.devtools = true

import store from './store'

instance.use(store).use(router).mount('#app')
